$contact-center: false;
@import '~stylesOpenBank';

.ok-informative-list {
    width: 100%;

    &__title {
        @include fontType('paragraph-large', true);
        margin: 0 -10px 30px;
        padding: 10px 10px 14px;
        background-color: $ok-background-color--first-layer;
        color: $ok-font-color--primary;
    }

    &__unstyled-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__title-icon {
        margin-left: 2px;
    }
}
