$contact-center: false;
@import '~stylesOpenBank';

.payroll-account-upgrade-to-payroll-account {
    @include fontType('paragraph-large');
    display: block;
    width: 100%;
    padding: 0;
    border-radius: 5px;
    background-color: $ok-color-white;
    overflow: auto;

    &__info-icon {
        position: relative;
        left: 7px;
        width: 70px;
        height: 70px;
        margin-top: 10px;
        float: left;
        background-image: url('~assets/images/icon-information.svg');
        background-repeat: no-repeat;
        background-position: left top;
        background-size: 70px 70px;
        overflow: hidden;
    }

    &__highlight {
        padding: 30px 30px 0;
        overflow: auto;
    }

    &__messages {
        padding-left: 30px;
        overflow: auto;
    }

    &__title {
        height: 70px;
        margin-top: 10px;
    }

    &__message {
        @include fontType('paragraph');
        padding-top: 30px;
        color: $ok-font-color--secondary;
    }

    &__footer {
        width: 100%;
        padding: 0 30px 10px 130px;
        float: right;
    }

    &__separator {
        height: 1px;
        border-bottom: 1px solid $ok-line-color--first-level;
    }

    &__upgrade-button {
        padding: 40px 30px 30px 130px;
        float: right;
    }

    &__continue-button {
        margin-top: 20px;
        float: right;
        overflow: auto;
    }
}
