$contact-center: false;
@import '~stylesOpenBank';

.broker-investment-specialist-modal {
    position: relative;
    width: 400px;

    &__title {
        @include fontType('paragraph-large', true);
    }

    &__field {
        padding-top: 10px;
        padding-bottom: 30px;

        textarea {
            resize: none;
        }
    }

    &__error-summary {
        padding-top: 10px;
    }
}
