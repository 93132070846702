$contact-center: false;
@import '~stylesOpenBank';

.legal-text-block {
    height: 300px;
    padding: 11px;
    border: 1px solid $ok-color-wealth-others;
    color: $ok-color-wealth-others;
    overflow-y: scroll;

    &__bottom-element {
        height: 2px;
    }
}
