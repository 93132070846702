$contact-center: false;
@import '~stylesOpenBank';

.wizard-header-menu {
    padding: 10px 40px 0;
    padding-bottom: 0;

    &__title {
        @include fontType('heading-type-4', true);
        display: inline;
        clear: both;
    }

    &__link-list {
        display: inline-block;
        margin-right: 7px;
    }

    &__link-list-item {
        display: inline-block;
        margin-right: 19px;

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__right-content {
        float: right;
    }

    &__extra-content {
        display: inline-block;
        margin-left: $ok-vertical-space--small;
    }
}
