$contact-center: false;
.phone-input {
    &__prefix {
        display: inline-block;
        width: 25%;
        padding-right: 17px;
    }

    &__number {
        display: inline-block;
        width: 75%;
        padding-left: 7px;
    }
}
