$contact-center: false;
@import '~stylesOpenBank';

.date-filter {
    padding-bottom: 24px;
    border-bottom: 1px solid $ok-line-color--default;

    &__last {
        padding-bottom: 0;
        border-bottom: 0;
    }

    &__title {
        @include fontType('paragraph', true);
        margin-top: 36px;
        margin-bottom: 19px;
        padding-bottom: 11px;
        border-bottom: 1px solid $ok-line-color--default;
        text-transform: capitalize;
    }

    &__radio-option {
        margin: 6px 0;
    }

    &__radio-label {
        margin-left: 10px;
    }

    &__radio-label-primary {
        @include fontType('paragraph');
        color: $ok-font-color--primary;
    }

    &__radio-label-secondary {
        @include fontType('paragraph');
        color: $ok-font-color--secondary;
        font-style: italic;
    }

    &__custom-ranges {
        padding: 0 0 10px 40px;
    }

    &__label-from,
    &__label-to {
        @include fontType('paragraph', true);
        margin-bottom: 19px;
    }

    &__apply {
        @include mainButton;
    }

}
