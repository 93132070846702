$contact-center: false;
@import '~stylesOpenBank';
// sass-lint:disable no-important

.product-banner-header {
    &__card {
        @include box-highlight;
        @include hoverBackground;
        width: 100%;
        background-color: $ok-color-white;

        & + & {
            margin-top: 6px;
        }
    }

    &__container {
        position: relative;
        width: 100%;
        padding-top: 31px;
        padding-right: 29px;
        padding-bottom: 33px;
        padding-left: 30px;
    }

    &__container-badge {
        position: absolute;
        right: $ok-space-padding-15;
    }

    &__title {
        @include fontType('heading-type-5', true);
        position: relative;
        width: 70%;
        padding-bottom: 15px;
        color: $ok-font-color--primary;

        .icon {
            width: 25px;
            height: 25px;
        }
    }

    &__subtitle {
        @include fontType('paragraph-large', true);
        padding-bottom: 15px;
        color: $ok-font-color--primary;
    }

    &__subtitle-color {
        color: $ok-font-color--secondary;
    }

    &__body {
        @include fontType('paragraph');
        text-align: center;
    }

    &__button {
        padding-top: 15px;
    }

    &__message-bold {
        @include fontType('paragraph', true);
    }

    &__image {
        border-radius: 50%;
    }

    &__more-info-message {
        margin-top: 20px;
    }

    &__icon {
        width: 100px !important;
        height: 100px !important;
        border: solid 2px $ok-color-oxford-20;
        border-radius: 50%;
    }

    &__message {
        width: 645px;
        margin-right: auto;
        margin-left: auto;
    }

    &__custom-container {
        padding-top: 15px;
    }
}
