$contact-center: false;
@import '~stylesOpenBank';

.diamond-premium-via-t-visa {
    &__tooltip {
        @include fontType('paragraph');
        padding-bottom: 7px;
    }

    &__tooltip-title {
        @include fontType('paragraph', true);
        color: $ok-font-color--primary;
    }

    &__tooltip-footer {
        padding-top: 10px;
        padding-bottom: 0;
        border-top: 1px solid $ok-line-color--first-level;
        color: $ok-error-color;
    }
}
