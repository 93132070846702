$contact-center: false;
@import '~stylesOpenBank';

.pre-leave-modal {

    &__button-wrapper {
        display: inline-block;
        min-width: 200px;
        margin-left: 20px;
    }

    @media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        .button {
            margin-bottom: 20px;
        }
    }
}
