$contact-center: false;
@import '~stylesOpenBank';

.link-to-button {
    color: #fff !important;
    text-decoration: none;
    padding: 10px !important;
    display: inline-table;
}

.commercial-ads {
    margin-bottom: 5px;
    text-align: left;

    &__cross-selling {
        max-width: $global-max-width;
        padding-top: 30px;
        padding-bottom: 105px;

        &--title {
            @include fontType('heading-type-4', true);
            text-align: center;
        }
    }

    &__figure-body {
        position: relative;
        height: 380px;
        margin-top: 30px;
    }

    &__content-width-background {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        cursor: pointer;
    }

    &__figure-container {
        position: relative;

        &--card {
            width: 325px;
            height: 217px;

            img {
                width: 100%;
            }

            &-medium {
                width: 240px;
                height: 160px;
            }
        }
    }

    &__figure-container-img {
        width: 100%;
        border-radius: 12px;
    }

    &__figure-cross-sell {
        position: absolute;
        top: 140px;
        left: 50px;
        text-align: left;
        cursor: pointer;

        h2,
        h3 {
            color: $ok-font-color--primary-inverse;
        }

        h2 {
            @include fontType('heading-type-6', true);
            max-width: 190px;
        }

        h3 {
            @include fontType('paragraph-large');
            max-width: 192px;
        }
    }

    &__empty-wafer {
        padding: 3em 1em 1em;

        &--bg-color {
            background-color: $ok-color-white;
        }
    }

    &__image-cross-sell {
        &:hover {
            cursor: pointer;
        }
    }
}