$contact-center: false;
@import '~stylesOpenBank';

.wizard-error-step {
    @include fontType('paragraph-large');
    display: block;
    width: 100%;
    padding: 0;
    border-radius: 5px;
    background-color: $ok-color-white;
    overflow: auto;

    &__highlight {
        padding: 30px 30px 0;
        overflow: auto;
    }

    &__icon {
        width: 70px;
        height: 70px;
        margin-top: 10px;
        padding-left: 55px;
        float: left;
        background-repeat: no-repeat;
        background-position: left top;
        background-size: 70px 70px;
        overflow: auto;
    }

    &__error-icon {
        background-image: url('~assets/images/icon-error.svg');
    }

    &__info-icon {
        background-image: url('~assets/images/icon-information.svg');
    }

    &__messages {
        padding-left: 30px;
        overflow: auto;
    }

    &__empty-line {
        height: 5px;
    }

    &__error-sub-message {
        @include fontType('paragraph-large');
        margin-top: 7px;
        color: $ok-font-color--secondary;
    }

    &__error-tag-label-message {
        @include fontType('paragraph');
        display: inline-block;
        margin-left: 25px;
    }

    &__separator {
        height: 1px;
        border-bottom: 1px solid $ok-line-color--first-level;
    }

    &__content,
    &__footer {
        width: 100%;
        padding: 0 30px 10px 130px;
    }

    &__content {
        margin-bottom: 20px;
    }

    &__foot-buttons {
        overflow: auto;
    }

    &__main-button {
        margin-left: 20px;
        float: right;
    }


    &__cancle-button {
        float: left;
    }


    &__secondary-button {
        float: right;
    }
}
