$contact-center: false;
@import '~stylesOpenBank';

.input-broker-edit-field {
    @include fontType('heading-type-4', true);
    max-width: 100%;
    padding: 0 10px 0 0;
    padding-right: 10px;
    border: 0;
    border-bottom: 1px solid $ok-color-oxford-10;
    outline: none;
    background: transparent;
    color: $ok-font-color--primary;
}
