$contact-center: false;
@import '~stylesOpenBank';

.mortgage-request-manager-info {
    @include clearfix;
    @include fontType('paragraph');

    &__box-title {
        @include fontType('heading-type-6', true);
        margin-bottom: 15px;
        padding: 0;
        border: 0;
        color: $ok-font-color--primary;
    }

    &__img-wrapper {
        width: 70px;
        height: 100%;
        margin-top: 5px;
        float: left;
    }

    &__img {
        width: 50px;
        height: 50px;
        border: 1px solid $ok-color-oxford-base;
        border-radius: 25px;
    }

    &__pic-slider {
        display: inline-block;
        width: 50px;
        height: 50px;
        overflow: hidden;
    }

    &__data-wrapper {
        float: left;

        &--compact {
            max-width: 250px;
        }
    }

    &--full-name {
        @include fontType('paragraph-large', true);
    }

    &__data-line {
        display: block;
        margin-top: 6px;
        color: $ok-font-color--primary;
    }

    &__name-text {
        @include fontType('paragraph', true);
    }

    &__email-text {
        color: $ok-highlight-color--primary;
    }
}

