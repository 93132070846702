$contact-center: false;
@import '~stylesOpenBank';

.deposit-thirteen-months-linked-maturities {
    &__initial-modal-body {
        @include fontType('paragraph');
    }

    &__initial-modal-message {
        margin: 20px 0;

        .message__text {
            @include fontType('paragraph');
            margin: 0 0 0 30px;
            text-align: left;
        }
    }

    &__initial-modal-summary-container {
        margin-top: 25px;
        margin-bottom: 25px;

        div {
            margin-bottom: 20px;
        }
    }

    &__initial-modal-phones-container {
        @include fontType('paragraph-large');
        margin-top: 15px;
    }

    &__initial-modal-phone-icon {
        margin-right: 10px;
    }

    &__initial-modal-phone {
        margin-right: 25px;
    }

    &__initial-modal-table-container {
        margin-bottom: 25px;
    }

    &__initial-modal-table {
        width: 100%;
    }

    &__initial-modal-table-row {
        height: 25px;
    }

    &__initial-modal-table-first-column {
        @include fontType('caption');
        text-align: left;
    }

    &__initial-modal-table-second-column {
        @include fontType('caption', true);
        text-align: right;
    }

    &__initial-modal-note-text,
    &__additional-info--note-text {
        @include fontType('caption', true);
        margin-top: 30px;
    }

    &__additional-info {
        @include fontType('paragraph');
        margin-bottom: 30px;
    }

    &__slider-tooltip-header {
        @include fontType('paragraph', true);
        padding-bottom: 10px;
    }

    &__slider-tooltip-cell {
        padding: 6px 20px;

        & + td {
            padding-left: 0;
        }
    }

    &__slider-tooltip-footer-cell {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        background-color: $ok-background-color--first-layer;
        line-height: 56px;
        white-space: nowrap;

        & + td {
            @include fontType('paragraph-large', true);
        }
    }

    &__custom-error-text {
        padding-bottom: 30px;
    }

    &__custom-error-footer {
        padding-top: 30px;
    }

    &__custom-error-cell {
        padding: 6px 0;
    }
}
