$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';

.glb-position-financial {
    @include CC {
        padding-right: 10px;
        padding-left: 10px;
    }

    &-section {
        @include box-highlight;
        @include hoverBackground;

        &--is-expanded {
            @include expanded-box-highlight;
        }

        &__content {
            position: relative;
            padding: 20px;
            background-color: $ok-color-white;

            &__empty-state {
                @include hoverBackground;
                background-color: $ok-color-white;

                .empty-state {
                    padding: 3em 1em 1em;
                }
            }

            &--wealth {
                background-color: $ok-background-color--default-wealth;
            }

            &--is-expanded {
                @include active-box-left-border($ok-highlight-color--primary, 20px);
                padding-bottom: 5px;
            }

            &--is-expanded-wealth {
                @include active-box-left-border($ok-highlight-color--primary, 20px);
                background-color: $ok-background-color--default-wealth;
            }

            &--is-clickable {
                cursor: pointer;
            }
        }
    }
}
