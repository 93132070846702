$contact-center: false;
@import '~stylesOpenBank';

.promotion-banner {
    position: relative;
    margin: 0 auto;

    &--banner-a {
        width: 385px;
    }

    &--banner-b {
        width: 285px;
        height: 480px;
    }

    &--banner-c {
        width: 785px;
        height: 197px;
    }

    &__empty-banner {
        margin: 0 auto 30px;
        background-color: $ok-color-white;
        text-align: left;

        &--banner-a {
            position: relative;
            width: 385px;
            height: 400px;
            padding: 180px 0 0 30px;
            background: url('~assets/images/banner-a.jpg') no-repeat center;
            background-size: cover;
        }

        &--banner-b {
            height: 480px;
            padding: 240px 0 0 30px;
            background: url('~assets/images/banner-b.jpg') no-repeat center;
            background-size: cover;
        }

        &--banner-c {
            width: 785px;
            height: 197px;
            padding: 30px 0 0 97px;
            background: url('~assets/images/banner-c.jpg') no-repeat center;
            background-size: cover;
        }

        &--banner-d {
            position: relative;
            width: 380px;
            height: 410px;
            padding: 180px 0 0 30px;
            background: url('~assets/images/banner-d.jpg') no-repeat center;
            background-size: cover;
        }

        &--primary-text {
            @include fontType('heading-type-6', true);
            margin-bottom: 20px;
            color: $ok-font-color--primary-inverse;
        }

        &--secondary-text {
            @include fontType('paragraph-large');
            margin-bottom: 30px;
            color: $ok-font-color--primary-inverse;

            &-short {
                max-width: 230px;
                margin-bottom: 30px;
            }

            &-medium {
                max-width: 300px;
            }

            &-large {
                max-width: 625px;
                margin-bottom: 20px;
            }
        }
    }

    &__figure-container {
        position: relative;
        height: 100%;
    }

    &__figure-banners {
        position: absolute;
        text-align: left;

        &--banner-a {
            top: 155px;
            left: 30px;
            max-width: 290px;
        }

        &--banner-b {
            top: 225px;
            left: 30px;
            max-width: 218px;
        }

        &--banner-c {
            top: 15px;
            left: 98px;
            max-width: 635px;
        }

        h2,
        h3 {
            color: $ok-font-color--primary-inverse;
        }

        h2 {
            @include fontType('heading-type-6', true);
        }

        h3 {
            @include fontType('paragraph-large');
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__link-button-left {
        position: absolute;

        &--banner-a {
            bottom: 32px;
            left: 30px;
        }

        &--banner-b {
            top: 410px;
            left: 30px;
        }

        &--banner-c {
            top: 140px;
            left: 96px;
        }
    }

    &__link-button-center {
        position: absolute;
        width: 100%;
        text-align: center;

        &--banner-a {
            bottom: 32px;
        }

        &--banner-b {
            top: 410px;
        }

        &--banner-c {
            top: 140px;
        }
    }

    &__link-button-right {
        position: absolute;

        &--banner-a {
            right: 30px;
            bottom: 32px;
        }

        &--banner-b {
            top: 410px;
            right: 30px;
        }

        &--banner-c {
            top: 140px;
            right: 96px;
        }
    }

    &__adobe-ads {
        color: $ok-font-color--primary-inverse;

        .slick-list {
            overflow: inherit;
        }

        .slick-dots {
            top: 0;
            height: 20px;
            text-align: right;

            button {
                &:before {
                    color: $ok-font-color--secondary-inverse;
                    opacity: 1;
                }
            }

            .slick-active {
                button {
                    &:before {
                        color: $ok-error-color;
                    }
                }
            }
        }
    }
}
