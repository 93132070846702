$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';


.signature-pattern-sms-input {
    margin: 10px 0;

    &__subtitle {
        @include fontType('paragraph');
        margin: 5px 0 10px;

        &--hidden {
            display: none;
        }
    }

    &__title {
        @include fontType('paragraph', true);
    }

    &__icon {
        width: 24px;
        height: 24px;
        margin-left: 15px;
        vertical-align: middle;

        &-container {
            @include CC {
                position: absolute;
            }

            line-height: 44px;
        }
    }

    &__input-container {
        @include If-CC-else-webapp('width', '100%', '60%');
        display: inline-block;

        input {
            text-transform: uppercase;
        }
    }

    &__trusted-warning {
        width: 60%;
        margin-top: $ok-space-margin-l;

        .ok-message__icon--informative {
            font-size: $ok-font-size--xxlarge;
            font-weight: normal;
        }
    }
}
