// sass-lint:disable class-name-format, no-important
@import '~stylesOpenBank';

.ok-select {

    .Select-control {
        @include fontType('paragraph');
        height: 46px;
        border: 1px solid $ok-color-oxford-30 !important;
        border-radius: 2px;
        color: $ok-font-color--secondary;
        box-shadow: none !important;
    }

    .is-open {
        .Select-control {
            outline: none;
            box-shadow: 0 0 6px 1px rgba($ok-color-oxford-base, .2) !important;
        }
    }

    .Select-arrow {
        border-width: 5px 4px 2.5px;
        border-color: $ok-color-wealth-others transparent transparent;
    }

    .Select-menu-outer {
        margin-top: 5px;
        border: 1px solid $ok-line-color--default;
        border-radius: 3px;
    }

    .Select-option {
        @include fontType('paragraph');
        color: $ok-font-color--secondary;

        &.is-focused,
        &.is-selected {
            background-color: $ok-background-color--first-layer;
        }

        &.is-disabled {
            opacity: .5;
        }
    }

    .Select-value {
        padding-right: 20px !important;
        line-height: 46px !important;
    }

    .Select-placeholder {
        color: $ok-font-color--secondary;
        line-height: 46px;
    }

    &--error {
        .Select-control {
            border-color: $ok-error-color !important;
            background-color: rgba($ok-error-color, .05) !important;

            .Select-input {
                &:focus {
                    padding: 0;
                }
            }
        }
    }

    &--open-up {
        .Select-menu-outer {
            top: auto;
            bottom: 100%;
            margin-bottom: 5px;
            border: 1px solid $ok-line-color--default;
            border-radius: 3px;
        }
    }

    .is-disabled {
        .Select-control {
            background-color: $ok-color-oxford-20 !important;
        }
    }
}
