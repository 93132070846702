$contact-center: false;
@import '~stylesOpenBank';

.webapp {
    .tutorial-open {
        display: inline-block;
        position: relative;
        width: 30px;
        height: 30px;
        margin-left: $ok-horizontal-space--small;
        font-size: 0;
        line-height: 30px;
        text-align: center;
        vertical-align: middle;
        z-index: 1;

        img {
            width: 18px;
            height: 18px;
            border-radius: 18px;
            line-height: 18px;
            box-shadow: 0 0 0 6px rgba($ok-highlight-color--secondary, .2);
            vertical-align: middle;

            &:hover {
                box-shadow: 0 0 0 6px rgba($ok-highlight-color--secondary, .4);
            }
        }
    }

    .tutorial-overlay {
        @include fontType('heading-type-3');
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($ok-color-oxford-base, .9);
        overflow: hidden;
        z-index: 999990;

        &__close {
            position: absolute;
            top: 10px;
            right: 26px;
            color: $ok-font-color--primary-inverse;
            font-size: 26px;
            cursor: pointer;
        }

        &__content-img-tutorial {
            padding: 21px;
        }

        &__top-space {
            position: relative;
            width: 100%;
            height: 30px;
        }

        &__title {
            @include fontType('heading-type-2');
            position: relative;
            width: 100%;
            color: $ok-font-color--primary-inverse;
            text-align: center;
        }

        &__subtitle {
            @include fontType('heading-type-6');
            position: relative;
            width: 100%;
            color: $ok-font-color--primary-inverse;
            text-align: center;
        }

        &__slider {
            position: absolute;
            top: 54px;
            width: 100%;
            height: 700px;
            margin: 50px auto;
            padding-top: 0;
            padding-right: 80px;
            padding-bottom: 0;
            padding-left: 80px;
            text-align: center;
        }

        &__image {
            width: 100%;
            height: 100%;
            margin: auto;
            padding: 0 20px;
        }

        &__prev-button {
            position: absolute;
            right: 50%;
            bottom: -7%;
            z-index: 999999;
        }

        &__next-button {
            position: absolute;
            bottom: -7%;
            left: 50%;
            min-width: 135px;
            z-index: 999999;

            // override common width button
            button {
                min-width: 135px;
                margin: 0 10px;
            }
        }

        &__start-options {
            max-width: 600px;
            margin: auto;
            padding-top: 8px;
            border-top: 1px solid rgba($ok-color-white, .5);
        }

        &__start-button {
            margin-top: 50px;
            text-align: center;
        }

        &__tutorial-checkbox {
            color: $ok-font-color--primary-inverse;
        }

        &__background-legal-information {
            position: absolute;
            top: 87px;
            left: 0;
            width: 100%;
            height: 435px;
            background-image: url('~assets/images/tutorials/es/general1/slide0.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: -1;
        }

        &__legal-information {
            @include fontType('paragraph');
            max-width: 600px;
            max-height: 350px;
            margin: 50px auto 25px;
            text-align: justify;
            overflow: auto;
        }

        &__legal-information-title {
            @include fontType('heading-type-5', true);
            margin-bottom: 20px;
        }

        // The following definitions overrule Slick standard CSS

        .slick-dots {
            top: -25px;

            li {
                button {
                    &:before {
                        color: $ok-font-color--primary-inverse;
                        font-size: 12px;
                        opacity: .75;
                    }
                }
            }

            .slick-active {
                button {
                    &:before {
                        color: $ok-highlight-color--primary;
                        opacity: 1;
                    }
                }
            }
        }


    }
}
