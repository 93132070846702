$contact-center: false;
@import './OKCustomSelect.override';
@import '~stylesOpenBank';

.ok-select {
    &__label {
        @include fontType('paragraph', true);
        display: inline-block;
        margin-bottom: 10px;
        color: $ok-font-color--primary;
    }

    &__label-invisible {
        visibility: hidden;
    }

    &__arrow {
        color: $ok-font-color--primary;
        font-size: 8px;
    }

    &__error-msg {
        @include fontType('caption');
        margin-top: 12px;
        color: $ok-error-color;
    }

    &__optional-element,
    &__sub-label-element {
        @include fontType('paragraph');
        display: inline-block;
        margin-bottom: 22px;
        color: $ok-font-color--secondary;
    }

    &__sub-label-element {
        display: block;
        margin-bottom: $ok-space-margin-xxs;
    }
}
