$contact-center: false;
@import '~stylesOpenBank';

.avatar {
    position: relative;

    &__icon-download {
        margin-bottom: 15px;
        color: $ok-error-color;
        font-size: 22px;
        font-weight: bold;
    }

    &--image, // deprecated as it does not comply with BEM
    &__image {
        background-color: $ok-background-color--first-layer;

        &--view-mode {
            width: 122px;
            height: 122px;
            border: 0;
        }

        &--edit-mode {
            width: 144px;
            height: 144px;
            border: 0;
        }

        &--small {
            width: 52px;
            height: 52px;
            border: $ok-space-border-width-s solid transparent;
            border-radius: 6px;
        }

        &--header-mode {
            width: 34px;
            height: 34px;
            border: 0;
        }

        &--disabled-mode,
        &--highlight-mode {
            width: 55px;
            height: 55px;
            margin-bottom: $ok-space-margin-xxs;
        }

        &--highlight-mode {
            border: $ok-space-border-width-m solid transparent;
            opacity: 1;
        }

        &--disabled-mode {
            opacity: .5;
        }

        &--info-mode,
        &--number-mode {
            width: 36px;
            height: 36px;
            border: solid $ok-space-border-width-s $ok-cta-color--secondary-inverse;
        }

        &--number-mode {
            background-color: $ok-cta-color--disabled-inverse;
        }
    }

    &__image-download {
        position: relative;
    }

    &__image-download-background {
        position: absolute;
        width: 100%;
        height: 100%;
        border: $ok-space-border-width-s dashed $ok-cta-color--disabled;
        border-radius: 50%;
        background: $ok-color-white;
        opacity: .8;
    }

    &__image-download-icon {
        position: absolute;
        width: 100%;
        height: 100%;
        margin-top: 36px;
        line-height: 1;
    }

    &__image-download-preview,
    &__image-empty {
        width: 122px;
        height: 122px;
    }

    &__image-download-preview {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        border-radius: 50%;
        line-height: 120px;
        text-align: center;
        cursor: pointer;
        opacity: 0;

        &:hover {
            opacity: 1;
        }
    }

    &__image-empty {
        background-image: url('~assets/images/logo-white-empty.jpg');
        background-repeat: no-repeat;
    }
}
