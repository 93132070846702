$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';

.wizard-success-step {
    @include fontType('paragraph-large');
    @include If-CC-else-webapp('border-radius', '0px', '5px');
    display: block;
    width: 100%;
    padding: 0;
    background-color: $ok-color-white;
    overflow: hidden;

    &__highlight {
        margin-bottom: 20px;
        padding: 30px 30px 0;
        overflow: auto;
    }

    &__success-icon {
        @include If-CC-else-webapp('background-size', '70px 70px', '60px 60px');
        width: 70px;
        height: 70px;
        margin-top: 10px;
        padding-left: 55px;
        float: left;
        background-image: url('~assets/images/icon-success.svg');
        background-repeat: no-repeat;
        background-position: left top;
        overflow: auto;

        &--pending {
            background-image: url('~assets/images/icon-pending.svg');
        }
    }

    &__messages {
        @include CC {
            margin-top: 15px;
        }
        padding-left: 30px;
        overflow: auto;
    }

    &__empty-line {
        height: 5px;
    }

    &__success-sub-message {
        @include fontType('heading-type-6');
        @include If-CC-else-webapp('font-size', '15px', '18px');
        margin-top: 7px;
        color: $ok-font-color--secondary;
    }

    &__success-tag-label-message {
        @include fontType('paragraph');
        display: inline-block;
        min-height: 30px;
        margin-left: 25px;
    }

    &__content {
        margin-bottom: 20px;
        padding: 0 $ok-space-padding-30 $ok-space-padding-m 130px;
    }

    &__footer {
        @include CC {
            margin-top: 30px;
            background-color: $ok-background-color--primary-emphasis;
        }
        @include If-CC-else-webapp('padding', '0 30px', '0 30px 0 130px');
    }

    &__separator {
        @include If-CC-else-webapp('padding', '0 30px 20px 130px', '');
        height: 1px;
        border-bottom: 1px solid $ok-color-line-first;
    }

    &__foot-buttons {
        @include If-CC-else-webapp('margin', '0px', '20px 0px');
        padding: 10px 0;
        overflow: auto;
    }

    &__main-button {
        margin-right: 20px;
        margin-left: 20px;
        float: right;
    }

    &__secondary-button {
        float: right;
    }

    @include CC {

        .buttons-base--additional-action-primary {
            @include fontType('paragraph');
            padding: 0;
            color: $ok-font-color--primary-inverse;

            &:hover {
                color: $ok-font-color--primary-inverse;
            }
        }

        .ok-box-title__separator {
            display: none;
        }

        &-full-width {
            .wizard-success-step__highlight {
                width: 100%;
            }
        }
    }
}
