$contact-center: false;
@import '~stylesOpenBank';

.signature-passcode {
    &__container {
        display: inline-block;
        vertical-align: middle;
    }

    &__boxes {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid $ok-line-color--default;
    }

    &__box {
        display: inline-block;
        width: 44px;
        height: 44px;
        margin: 0 3px 0 4px;
        text-align: center;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        span {
            color: $ok-font-color--secondary;
            vertical-align: sub;
        }

        &--empty {
            padding-top: 9px;
            vertical-align: bottom;

            span {
                @include fontType('paragraph');
                color: $ok-font-color--secondary;
            }
        }

        &--error {
            input {
                border: 1px solid $ok-error-color;
                background: $ok-background-color--first-layer-wealth;
            }
        }
    }

    &__attempts {
        @include fontType('paragraph');
        display: inline-block;
        margin-left: 25px;
        color: $ok-font-color--secondary;

        span {
            display: block;
        }
    }
}


