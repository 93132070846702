$contact-center: false;
@import '~stylesOpenBank';

.biocatch-exception-modal-wrapper {

    .ok-modal__content {
        height: auto;
    }

    .ok-modal__footer-navigation {
        padding-top: 0;
        border: 0;

        &--right {
            margin-left: 0;
            float: none;
            text-align: center;
        }
    }
}

.biocatch-exception-modal {
    &__icon-img {
        margin: $ok-space-margin-30 0 $ok-space-margin-l;
        text-align: center;

        &--icon {
            width: 180px;
        }
    }

    &__primary-text {
        @include PrimaryFontBold(24px);
        margin-bottom: $ok-space-margin-15;
    }

    &__secondary-text {
        @include fontType('paragraph');
        padding: 0 $ok-padding-space--xxl;
        text-align: center;
    }

    &__divider {
        border-bottom: 2px solid $ok-color-oxford-20;
    }
}
