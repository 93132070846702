$contact-center: false;
@import '~stylesOpenBank';

// sass-lint:disable class-name-format
.broker-investment-specialist-modal {
    &__options {
        .Select-menu-outer {
            .Select-option {
                padding-left: 2em;

                &.is-disabled {
                    padding-left: 1em;
                    color: $ok-font-color--primary;
                    font-weight: bold;
                    cursor: default;
                }
            }
        }
    }
}
