$contact-center: false;
@import '~stylesOpenBank';

.accounts-transfer-redirector{
    margin-top: $ok-space-margin-30;

    &__title {
        @include fontType('heading-type-5', true);
        margin-bottom: $ok-space-margin-m;
    }

    &__message {
        @include fontType('paragraph');
        margin: 0;
    }

    &__button {
        margin-top: $ok-space-margin-m;
        text-align: right;
    }
}