$contact-center: false;
@import '~stylesOpenBank';

// (nicolas.olmos):
// Type of cards -> [credit, debit, ecard, minicard]
// Sizes -> [large, medium]
// The BEM structure is the following one: card__field--type-size
// If no size is specified, we assume that is a large card.
// If no type is specified, we assume that is a credit/debit card.

.card {
    position: absolute;
    top: 0;
    left: 0;
    color: $ok-font-color--primary;
    text-shadow: -2px 0 1px $ok-color-white;

    &__expiration-date {
        @include PrimaryFontRegular(9px);
        position: absolute;
        top: 145px;
        left: 30px;
        width: 270px;

        &--medium {
            @include PrimaryFontRegular(9px);
            position: absolute;
            top: 105px;
            left: 20px;
            width: 200px;
        }

        &--ecard {
            @include PrimaryFontRegular($ok-font-size--xxxsmall);
            position: absolute;
            top: 43px;
            left: 245px;
            color: $ok-font-color--primary-inverse;
            text-shadow: -1px 0 1px $ok-font-color--primary;

            &-medium {
                @include PrimaryFontRegular($ok-font-size--xxxsmall);
                position: absolute;
                top: 30px;
                left: 180px;
            }
        }
    }

    &__card-number {
        @include fontType('paragraph-large');
        position: absolute;
        top: 110px;
        left: 30px;

        &--hide {
            visibility: hidden;
        }

        &--show {
            transition-delay: .2s;
        }

        &--big {
            @include fontType('paragraph');
            position: absolute;
            top: 130px;
            left: 20px;
        }

        &--medium {
            @include fontType('paragraph');
            position: absolute;
            top: 80px;
            left: 20px;

            span {
                margin-right: 8px;
            }
        }

        &--small {
            @include fontType('paragraph');
            position: absolute;
            top: 115px;
            left: 73px;

            span {
                margin-right: 6px;
            }
        }

        &--ecard {
            @include PrimaryFontRegular($ok-font-size--xxxsmall);
            position: absolute;
            top: 92px;
            left: 151px;
            width: 130px;
            color: $ok-font-color--primary-inverse;
            text-shadow: -1px 0 1px $ok-font-color--primary;

            &-medium {
                @include PrimaryFontRegular($ok-font-size--xxxsmall);
                position: absolute;
                top: 66px;
                left: 112px;
            }

            &-small {
                @include PrimaryFontRegular(8px);
                position: absolute;
                top: 56px;
                left: 155px;
            }
        }

        &--minicard {
            position: absolute;
            top: 125px;
            left: 103px;
            color: $ok-font-color--primary-inverse;

            &-medium {
                @include PrimaryFontRegular($ok-font-size--xxxsmall);
                position: absolute;
                top: 90px;
                left: 77px;
            }

            &-small {
                @include PrimaryFontRegular($ok-font-size--xxxsmall);
                position: absolute;
                top: 75px;
                left: 122px;
            }
        }

        &--only-number {
            top: 77px;
        }

        &--vanity {
            top: 122px;
        }

        span {
            margin-right: 15px;
        }
    }

    &__beneficiary-name {
        @include fontType('caption');
        position: absolute;
        top: 170px;
        left: 30px;
        width: 270px;

        &--medium {
            @include PrimaryFontRegular(9px);
            position: absolute;
            top: 122px;
            left: 20px;
            width: 200px;
        }

        &--ecard {
            @include PrimaryFontRegular($ok-font-size--xxxsmall);
            position: absolute;
            top: 105px;
            left: 151px;
            width: 130px;
            color: $ok-font-color--primary-inverse;
            text-shadow: -1px 0 1px $ok-font-color--primary;

            &-medium {
                @include PrimaryFontRegular($ok-font-size--xxxsmall);
                position: absolute;
                top: 76px;
                left: 112px;
                width: 111px;
            }

        }

        &-medium {
            @include fontType('caption');
        }
    }
}
