$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';

.glb-position-financial-card-header-col {
    @include CC {
        margin-left: 20px;
    }
    display: inline-block;
    margin-top: 15px;
    margin-left: 20px;
    color: $ok-font-color--primary;
    text-align: right;
    list-style-type: none;

    &__legend {
        @include fontType('paragraph');
        color: $ok-font-color--secondary;
    }

    &__amount {
        @include fontType('heading-type-5', true);
        display: flex;
    }

    &__amount-object {
        display: flex;
        align-items: center;
    }
}

@media only screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    .glb-position-financial-card-header-col {
        margin-left: 40px;

        &__legend {
            text-align: left;
        }

        &__amount-object {
            text-align: left;
        }
    }
}
