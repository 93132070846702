$contact-center: false;
@import '~stylesOpenBank';

.broker-edit-alias {
    height: 30px;
    max-height: 45px; //add for ie support
    color: $ok-font-color--primary;

    &__value {
        @include text-ellipsis;
        margin: 0;
        border-bottom: 1px solid transparent;

        &:hover {
            color: $ok-font-color--secondary;
        }

        &:after {
            padding-left: 15px;
            color: $ok-font-color--primary;
            font-family: 'OpenbankFont';
            font-size: 20px;
            content: '\E931';
            pointer-events: none;
        }

        &--error {
            border-bottom: 1px solid $ok-error-color;
        }
    }

    &__error-message {
        @include fontType('paragraph');
        color: $ok-error-color;
    }
}
