$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';

.wizard {
    border-radius: 2px;
    background-color: $ok-color-white;

    &__steps {
        padding: 40px;

        &--full-width {
            padding: 40px 0;
        }
    }

    &__header {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        background-color: $ok-background-color--first-layer;
    }

    @include CC {
        &__disabled-step {
            margin-top: 20px;
            margin-bottom: 20px;
            padding-top: 20px;
            opacity: .5;
            pointer-events: none;
        }

        &-vertical {
            $vertical-wizard-padding-adjustment: 40px; // To use border as the background color for the wizard steps.

            .wizard__steps {
                padding-bottom: 0;
            }

            &-step {
                margin: 0 (-$vertical-wizard-padding-adjustment);
                padding: 20px $vertical-wizard-padding-adjustment;
                border-bottom: 8px solid $ok-line-color--default;
            }
        }
    }
}
