$contact-center: false;
.printable-signature-pattern {

    &__container {
        margin-bottom: 1.5em;
    }

    &__print-button {
        margin-bottom: 1em;
    }
}
