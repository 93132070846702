$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';

.broker-investment-specialist {
    @include clearfix;
    @include fontType('paragraph');
    box-shadow: 0 2px 10px 0 rgba($ok-color-oxford-base, .15);

    &__img-wrapper {
        width: 70px;
        height: 100%;
        float: left;
    }

    &__img {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        object-fit: cover;
    }

    &__data-line {
        display: block;
        margin-top: 6px;
    }

    &__icon {
        margin-right: 10px;
    }

    &__contact-info {
        height: 65px;
        margin-top: 25px;
        color: $ok-font-color--primary;
    }

    &__hr {
        margin-top: 5px;
    }

    &__extra-info {
        @include fontType('paragraph');
        color: $ok-font-color--secondary;
    }

    &__pic-slider {
        display: inline-block;
        width: 50px;
        height: 50px;
        overflow: hidden;
    }

    &__sliding-wrapper {
        position: relative;
        width: 650px;
        height: 100%;
    }

    &__will-call-you {
        margin: 5px 0;

        a {
            @include fontType('paragraph', true);
            color: $ok-highlight-color--primary;
            cursor: pointer;

            &:hover,
            &:visited,
            &:link,
            &:active {
                color: $ok-color-red-80;
                text-decoration: none;
            }
        }
    }

    &__will-call-you-message {
        @include fontType('paragraph', true);
        margin-top: 5px;
    }

    &__telephone-code {
        display: inline-block;
        width: 32%;
        float: left;
    }

    &__telephone-input {
        display: inline-block;
        width: 68%;
        float: right;

        input {
            width: 100%;
            height: 46px;
        }
    }

    &__will-call-buttons {
        margin-top: 20px;
    }

    &__cancel-button {
        display: inline;
        margin-right: 48px;
        float: left;
    }

    &__icon-success {
        margin-top: 50px;
        font-size: 70px;
        font-weight: bold;
        text-align: center;
    }

    &__icon-success-label {
        @include fontType('heading-type-5', true);
        text-align: center;
    }

    &__success-message {
        @include fontType('paragraph-large', true);
        margin-top: 60px;
        margin-bottom: 50px;
        padding-right: 25%;
        padding-left: 21%;
        text-align: center;
    }

    &__icon-failure {
        margin-top: 50px;
        font-size: 70px;
        font-weight: bold;
        text-align: center;
    }

    &__icon-failure-label {
        @include fontType('heading-type-5', true);
        text-align: center;
    }

    &__failure-message {
        @include fontType('paragraph-large', true);
        margin-top: 60px;
        margin-bottom: 50px;
        padding-right: 25%;
        padding-left: 21%;
        text-align: center;
    }

    &__contact-options {
        @include fontType('paragraph', true);
        color: $ok-font-color--primary;

        &__phone-number {
            margin-bottom: 0;

            &:before {
                padding-right: 10px;
                color: $ok-highlight-color--primary-wealth;
                font-family: 'OpenbankFont';
                font-size: 16px;
                content: '\E965';
                vertical-align: middle;
                pointer-events: none;
            }
        }

        &__e-mail {
            @include text-ellipsis;
            margin-bottom: 0;

            &:before {
                padding-right: 10px;
                color: $ok-highlight-color--primary-wealth;
                font-family: 'OpenbankFont';
                font-size: 16px;
                content: '\E967';
                vertical-align: middle;
                pointer-events: none;
            }
        }
    }

    .custom-modal__close-icon {
        position: relative;
        z-index: 1;
    }

    .content-box {
        &__wrapper {
            padding-bottom: 10px;
        }
    }

    &__call-time-zone {
        @include fontType('caption');
        display: block;
        margin-top: 6px;
        color: $ok-font-color--secondary;
    }

    &__entrance {
        @include CC {
            @include fontType('paragraph-large', true);
            margin-top: -5px;
       }

        text-align: left;
    }

    &__entrance-text {
        @include fontType('paragraph');
    }

    &__entrance-here {
        @include fontType('paragraph', true);
        text-decoration: underline;
        cursor: pointer;
    }
}
