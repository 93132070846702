$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';


.signature-pattern {
    @include CC {

        &__loading-message {
            @include fontType('paragraph-large');
            color: $ok-font-color--primary;

            &-block {
                text-align: center;
            }
        }

        &__code-input-block {
            margin: 15px 0 0 66px;
        }

        &__sms-input-block {
            width: 60%;
            margin: 15px 0 0 66px;
        }

        &__printed-documents-question {
            margin-top: 1em;
            margin-bottom: 1em;
        }

        &__printed-documents-radio {
            display: inline-block;
        }
    }

    margin-bottom: 20px;

    &__confirmation-script {
        @include fontType('paragraph-large', true);

        &-title  {
            @include fontType('paragraph', true);
            display: block;
            margin-bottom: $ok-space-margin-m;
        }
    }
}
