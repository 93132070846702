$contact-center: false;
.create-app {
  height: 100%;

  &__spinner {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
