$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';

.ok-calculation-waffer {
    width: 100%;
    height: 100px;

    &__list {
        display: inline-block;
        width: 70%;
        height: 100%;
        padding-top: 3.5%;
        background-color: $ok-background-color--zero-layer;
        list-style: none;

        .ok-informative-list-item {
            @include If-CC-else-webapp('margin-right', $ok-space-margin-m, $ok-space-margin-l);
            display: inline-block;
            margin-bottom: 0;
        }
    }

    &__outstanded-section {
        display: inline-block;
        width: 30%;
        height: 100%;
        padding-top: 15px;
        background-color: $ok-background-color--primary-emphasis;
        color: $ok-font-color--primary-inverse;
        vertical-align: top;
    }

    &__outstanded-content {
        margin-left: 17px;
    }

    &__outstanded-label {
        @include fontType('paragraph');
    }

    &__outstanded-text {
        @include fontType('heading-type-3');
    }
}
