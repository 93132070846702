$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';


.signature-pattern-code-input {

    &__signature-ok-container {
        padding-left: 20px;
        float: left;
    }

    &__title {
        @include fontType('paragraph', true);
    }

    &__error {
        @include fontType('caption');
        color: $ok-highlight-color--primary;
    }

    &__description {
        @include fontType('paragraph');
        margin: $ok-space-margin-10 0 $ok-space-margin-15;
    }

    &__send-key-info {
        margin: $ok-space-margin-15 0 $ok-space-margin-xxxl;
        
        a {
            @include fontType('paragraph', true);
            color: $ok-link-color;
        }
    }

    &__signature-button {
        @include fontType('paragraph-large');

        padding: 10px;
        border: 1px solid $ok-graphics-color--fourth;
        border-radius: 5px;
        background-color: $ok-color-white;
        color: $ok-graphics-color--fourth;

        &--hidden {
            display: none;
        }
    }

    &__icon-container {
        line-height: 44px;
    }

    &__icon {
        width: 24px;
        height: 24px;
        vertical-align: middle;
    }

    &__invalid-msg {
        width: 338px;
    }

    @include CC {
        &__error {
            @include fontType('caption');
            margin: 8px 0;
            color: $ok-error-color;
            text-align: left;
        }
    }
}
