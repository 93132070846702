$contact-center: false;
@import '~stylesOpenBank';
// TODO: CC Use special CC Grid Instead of stylesOpenBankBootstrap


.custom-message-box {
    background-color: transparent;

    &__body {
        width: 100%;
        padding-top: 65px;
        float: left;
        border-radius: 4px;
        background-color: $ok-color-white;

        &--no-extra-content {
            padding-bottom: 65px;
        }
    }

    &__footer {
        height: 38px;
        clear: left;
        border-radius: 4px;
        background-color: $ok-color-white;
    }

    &__content-message {
        display: table;
        margin: 0 auto;
    }

    &__buttons {
        @include linear-gradient(to right, $ok-color-oxford-80, $ok-color-oxford-80);
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }

    &__right-button {
        position: absolute;
        right: 0;
        bottom: 0;
        padding-right: 20px;
    }

    &__link {
        @include fontType('caption');
        color: $ok-font-color--primary-inverse;
        text-transform: uppercase;
    }

    &__extra-content {
        margin-top: 50px;
        padding: 0 21px;
    }

    &__divider {
        width: 100%;
        height: 20px;
        float: left;
        border-bottom: 4px solid transparent;
    }

    &__content-icon {
        margin-right: 20px;
        float: left;
    }

    &__content-text {
        float: left;
    }

    &__span-icon {
        display: inline-block;
        position: relative;
        width: 66px;
        height: 66px;
    }

    &__icon-success {
        position: absolute;
        width: 66px;
        height: 66px;
        background: url('../../../assets/images/icon-success.svg') 0 0 no-repeat;
    }

    &__icon-warning {
        position: absolute;
        width: 66px;
        height: 66px;
        background: url('../../../assets/images/icon-warning.svg') 0 0 no-repeat;
    }

    &__title {
        @include fontType('heading-type-4', true);
        margin-top: 12px;
        margin-bottom: 6px;
    }

    &__subtitle {
        @include fontType('paragraph');
    }
}
