$contact-center: false;
@import '~stylesOpenBank';

.ok-table-row-details {
    position: relative;

    &__title {
        @include fontType('heading-type-6', true);
        margin: 20px 0;
        color: $ok-font-color--primary;
    }

    &__list {
        margin-top: 20px;
    }
}
