$contact-center: false;
@import '~stylesOpenBank';
@import './dropdownAccounts.override';

.dropdown-accounts {
    @include fontType('paragraph');
    width: 100%;

    &__account {
        position: relative;
        height: 46px;
        padding: 10px;

        &-icon {
            font-weight: bold;
        }

        &-description {
            @include text-ellipsis;

            &--height {
                @include fontType('paragraph');
                line-height: 30px;
            }

            &-alias {
                @include fontType('paragraph');
                color: $ok-font-color--primary;
            }

            &-iban,
            &-separator,
            &-type {
                @include fontType('paragraph');
                color: $ok-font-color--secondary;
            }

            &-amount {
                @include fontType('paragraph', true);
                color: $ok-font-color--primary;
            }

            &-negative-amount {
                @include fontType('paragraph', true);
                color: $ok-highlight-color--primary;
            }

            &-inactive-icon {
                padding-right: $ok-space-padding-10;
                color: $ok-color-oxford-80;
            }

            &-negative-balance {
                padding-right: $ok-space-padding-10;
                padding-left: $ok-space-nano;
                color: $ok-highlight-color--primary;
            }
        }
    }

    &__associated-cc-legend {
        @include fontType('paragraph');
        display: block;
        margin-top: 2px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 13px;
        color: $ok-font-color--secondary;
    }

    &__disclaimer {
        margin-top: $ok-space-margin-m;
    }
}
