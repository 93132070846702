$contact-center: false;
@import '~stylesOpenBank';

.wizard-progress-indicator {
    padding: 41px 30% 26px;
    border-bottom: 2px solid $ok-line-color--default;

    &__progress {
        display: inline-block;
        position: relative;
        text-align: center;

        &:after {
            position: absolute;
            left: 25px;
            top: 16px;
            width: calc(100% - 25px);
            height: 3px;
            background-color: $ok-background-color--primary-emphasis;
            content: '';
        }

        &:last-child {
            &:after {
                content: none;
            }
        }

        &--valid {
            &:after {
                background-color: $ok-highlight-color--primary;
            }
        }

        &--active {
            &:after {
                position: absolute;
                left: 35px;
                top: 21px;
                width: calc(100% - 35px);
                height: 2.8px;
                background-color: $ok-background-color--primary-emphasis;
                content: '';
            }
        }
    }

    &__item {
        @include fontType('paragraph');
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        margin: 5px 0;
        padding-top: 1px;
        border-radius: 50px;
        background-color: $ok-background-color--primary-emphasis;
        color: $ok-font-color--primary-inverse;

        &--active,
        &--valid {
            background-color: $ok-highlight-color--primary;
        }

        &--active {
            width: 35px;
            height: 35px;
            box-shadow: 0 2px 6px rgba($ok-color-oxford-base, .2);

            &:before {
                position: absolute;
                top: 59px;
                left: 10px;
                width: 15px;
                height: 15px;
                transform: rotate(45deg);
                border-top: 2px solid $ok-line-color--default;
                border-left: 2px solid $ok-line-color--default;
                background-color: $ok-color-white;
                content: '';
            }
        }

        &--valid {
            color: $ok-highlight-color--primary;

            &:after {
                position: absolute;
                left: 0;
                width: 100%;
                color: $ok-font-color--primary-inverse;
                content: '\2713';
            }
        }
    }
}
