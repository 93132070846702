$contact-center: false;
@import '~stylesOpenBank';

.pre-contractual-mail {
    position: relative;
    width: 100%;

    &__content {
        display: inline-block;
        margin-top: 33px;
    }

    &__email-container {
        display: inline-block;
        width: 50%;
        margin-bottom: 25px;
    }

    &__pre-contractual-mail__email-text {
        display: inline-block;
    }

    &__button-container {
        display: inline-block;
        width: 50%;
    }

    &__title-email {
        @include fontType('paragraph');
        margin: 0;
    }

    &__textarea {
        display: inline-block;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &__email {
        @include fontType('paragraph', true);
    }

    &__information {
        display: block;
        margin: 30px 0 35px;
        padding: 20px;
        background-color: $ok-background-color--first-layer;

        .icon-informacion {
            margin-right: 15px;
        }
    }

    &__legaltextview {
        margin-bottom: 26px;
    }
    
    &__block-info {
        width: 100%;
        margin-bottom: 11px;
    }

    .buttons-base {
        &--tasks-transparent {
            display: inline-block;
            position: relative;
            top: 10px;
            margin-left: 30px;
            background-color: $ok-color-transparent;
            color: $ok-font-color--primary;
            font-size: 40px;
        }
    }

    &__refresh-error {
        @include fontType('paragraph');
        position: absolute;
        width: 250px;
        margin-top: 20px;
        color: $ok-error-color;
    }

    &__button {
        margin-bottom: 17px;
        float: right;
    }

    &__button-error {
        @include fontType('paragraph');
        position: absolute;
        width: 220px;
        margin-top: 10px;
        color: $ok-error-color;
    }

    &__text-value {
        @include fontType('paragraph', true);
    }

    &__textbox {
        max-height: 100px;
        padding: 11px;
        border: 1px solid $ok-color-wealth-others;
        color: $ok-color-wealth-others;
        overflow-y: scroll;
    }

    &__textbox-large {
        height: 300px;
        padding: 11px;
        border: 1px solid $ok-color-wealth-others;
        color: $ok-color-wealth-others;
        overflow-y: scroll;
    }

    &__legal-text {
        margin-left: 50px;
    }

    &__warning-box {
        .icon-warning {
            position: absolute;
            width: 30px;
            height: 30px;
            margin-top: -4px;
            margin-left: 10px;
            background: url('../../../assets/images/icon-warning.svg') 0 0 no-repeat;
        }
    }
}
