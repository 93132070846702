$contact-center: false;
@import '~stylesOpenBank';

.cross-selling {
    height: 380px;
    background-repeat: no-repeat;
    text-align: left;
    box-shadow: 0 -100px 150px rgba($ok-color-oxford-base, .7) inset;

    &:hover {
        cursor: pointer;
    }

    &__content {
        position: relative;
        margin-top: 30px;
    }

    &__title {
        @include fontType('heading-type-6', true);
        position: absolute;
        top: 200px;
        left: 35px;
        max-width: 175px;
        color: $ok-font-color--primary-inverse;
    }

    &__description {
        @include fontType('paragraph-large');
        position: absolute;
        top: 270px;
        left: 35px;
        max-width: 185px;
        color: $ok-font-color--primary-inverse;
    }

    &--accounts {
        background-image: url('~assets/images/crosselling-cuentas.jpg');
        background-size: cover;
    }

    &--cards {
        background-image: url('~assets/images/crosselling-tarjetas.jpg');
        background-size: cover;
    }

    &--mortgages {
        background-image: url('~assets/images/crosselling-prestamos.jpg');
        background-size: cover;
    }

    &--deposits {
        background-image: url('~assets/images/crosselling-depositos.jpg');
        background-size: cover;
    }

    &--investments {
        background-image: url('~assets/images/crosselling-inversiones.jpg');
        background-size: cover;
    }
}
