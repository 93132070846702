$contact-center: false;
@import '~stylesOpenBank';

.mortages-collective-tag-tooltip {

    padding-left: 18px;

    &__text {
        display: inline-block;
        margin-right: $ok-space-margin-xxxs;

        &--bold {
            @include fontType('paragraph', true);
        }
    }
}
