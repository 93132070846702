// sass-lint:disable class-name-format
@import '~stylesOpenBank';

.stocks-contract-dropdown {
    .Select-option {
        &.stocks-contract-dropdown__option--grouped {
            &.is-selected {
                background-color: $ok-color-oxford-30;
            }
        }
    }
}
