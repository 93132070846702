$contact-center: false;
@import '~stylesOpenBank';

.next-button-wrapper {
    margin-top: 15px;

    &__next-button-container {
        height: 60px;
        background-color: $ok-color-white;
        overflow: hidden;
    }

    &__next-button {
        float: right;
    }
}
