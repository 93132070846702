$contact-center: false;
@import '~stylesOpenBank';

.block-info,
.block-info-checkbox {
    display: inline-block;
    position: relative;
    padding: 24px 24px 24px 69px;
    background-color: $ok-background-color--first-layer;

    &__content {
        @include fontType('paragraph-large');
        width: auto;
        color: $ok-font-color--primary;
    }

    &__icon-assistant {
        position: absolute;
        top: calc(50% - 12px);
        left: 24px;
        width: 21px;
        height: 24px;
        background: url('../../../assets/images/icon-specialist.svg') 0 0 no-repeat;
    }
}

.block-info-checkbox {
    &__icon-assistant {
        top: calc(10% + 24px);
    }

    &__content {
        .ok-checkbox {
            &__label {
                &:before,
                &:after {
                    top: calc(80%);
                    left: -43px;
                }

                &:after {
                    background-position-x: 2px;
                    background-position-y: 2px;
                }
            }

            &--error {
                padding: 0;
                border: 0;
                background-color: transparent;
            }
        }
    }
}
