$contact-center: false;
@import '~stylesOpenBank';

.last-days-date {
    @include fontType('paragraph-large', true);
    display: inline-block;
    vertical-align: middle;

    &--date-small {
        @include fontType('caption', true);
    }

    &--date-large {
        @include fontType('heading-type-6', true);
    }

    &__text {
        display: inline-block;
        color: $ok-font-color--primary-inverse;
    }

    &__icon {
        margin-right: $ok-space-margin-xxxs;
        font-size: 24px;
        vertical-align: middle;

        &--date-small {
            font-size: 18px;
        }

        &--date-large {
            margin-right: $ok-space-margin-xxs;
            font-size: 24px;
        }
    }
}
