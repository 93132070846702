$contact-center: false;
@import '~stylesOpenBankCC';

.glb-position-collapsable {
    .ok-accordion-body {
        @include If-CC-else-webapp('padding-right', '0px', '15px');
        margin-top: 20px;
    }

    .ok-collapsing-toggler {
        margin-top: 20px;
        margin-left: 0;
    }
}
