$contact-center: false;
@import '~stylesOpenBank';
@import './stocksContractDropdown.override';
@import './stocksContractDropdownContractGrouped.override';

.stocks-contract-dropdown {
    display: block;
    max-width: 742px;
    padding: 15px 0;

    &__additional-info-text {
        @include fontType('paragraph');
        display: block;
        margin-top: 10px;
        color: $ok-font-color--secondary;
    }

    &__value {
        @include fontType('paragraph');
        padding: 10px;

        &--highlighted {
            @include fontType('paragraph', true);
            color: $ok-font-color--primary;
        }

        &--medium {
            @include fontType('paragraph-large');
        }

        &--right {
            float: right;
        }

        &--light {
            color: $ok-font-color--secondary;
        }

        &--margin {
            margin-left: 7px;
        }
    }

    &__value-label {
        @include text-ellipsis;
        @include fontType('paragraph');
        display: inline-block;
        max-width: 50%;
        margin-right: 10px;
        float: left;
    }

    &__account-description-inactive-icon {
        padding-right: $ok-space-padding-10;
        float: left;
        color: $ok-color-oxford-80;
    }

    &__value-price {
        margin-left: 10px;
    }

    &__option {
        padding: 10px 0;
        color: $ok-font-color--secondary;
        cursor: pointer;

        &--subtitle {
            @include fontType('paragraph', true);
            padding: 5px 16px;
            border-top: 1px solid $ok-line-color--default;
            color: $ok-font-color--secondary;
            cursor: default;

            &:first-child {
                border-top-width: 0;
            }
        }

        &--grouped {
            margin-right: 5px;
            margin-bottom: 2px;
            margin-left: 5px;
            padding: 5px 26px;
            background-color: $ok-background-color--first-layer;

            &:hover {
                background-color: $ok-color-oxford-30;
            }
        }

        &--focused {
            background-color: transparentize($ok-color-wealth-others, .9);
        }

        &--highlighted {
            @include fontType('paragraph', true);
            margin: 0 10px;
            color: $ok-font-color--primary;
        }

        &--highlighted-principal {
            @include fontType('paragraph', true);
            color: $ok-font-color--primary;
        }

        &--right {
            float: right;
        }

        &--empty-right {
            margin-right: 12px;
            float: right;
        }
    }

    &__right-text-title {
        margin-right: 10px;
    }
}

.fund-subscription {
    max-width: 70%;
}
