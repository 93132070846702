$contact-center: false;
@import '~stylesOpenBank';

.ok-error-boundary {
    &__container {
        margin-top: 150px;
    }

    &__message {

        padding: 10px;
        transition: .2s linear;
        transition-delay: 1s;
        opacity: 1;
        z-index: 999;
    }
}
