// sass-lint:disable class-name-format
@import '~stylesOpenBank';

.stocks-contract-dropdown {

    .Select-input {
        position: absolute;
        height: 0;
        margin-top: -100px;
        margin-left: -100px;
        overflow: hidden;
    }

    .Select-option {
        &.is-selected {
            background-color: rgba($ok-color-oxford-20, .3);
        }

        &.is-focused {
            background-color: rgba($ok-color-oxford-10, .3);
        }
    }

}
