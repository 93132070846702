$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';

.deposit-nm {
    &__initial-modal-body {
        @include fontType('paragraph');
        overflow: hidden;

        ul {
            margin-top: $ok-space-margin-l;
        }

        li {
            margin-top: $ok-space-margin-s;
        }
    }

    &__initial-modal-message {
        @include CC {
            .message__text {
                @include fontType('paragraph');
                margin: 0 0 0 $ok-space-margin-30;
                text-align: left;
            }
        }
        margin: $ok-space-margin-m 0;
    }

    &__initial-modal-summary-container {
        margin-top: $ok-space-margin-l;

        div {
            margin-bottom: $ok-space-margin-m;
        }
    }

    &__initial-modal-phones-container {
        @include fontType('paragraph');
        margin-top: $ok-space-margin-15;
    }

    &__initial-modal-phone-icon {
        margin-right: $ok-space-margin-10;
    }

    &__initial-modal-phone {
        margin-right: $ok-space-margin-l;
    }

    &__initial-modal-table-container {
        margin-top: $ok-space-margin-l;
        margin-bottom: $ok-space-margin-l;
        padding: 0 40px;
    }

    &__initial-modal-table-last {
        padding-bottom: 8px;
        border-bottom: 1px solid $ok-color-oxford-30;
    }

    &__initial-modal-table {
        width: 100%;
    }

    &__initial-modal-table-row {
        height: 25px;
    }

    &__initial-modal-table-first-column {
        @include fontType('paragraph');
        padding-top: 8px;
        text-align: left;
    }

    &__initial-modal-table-second-column {
        @include fontType('paragraph', true);
        padding-top: 8px;
        text-align: right;
    }

    &__initial-modal-note-text,
    &__additional-info--note-text {
        @include fontType('caption', true);
        margin-top: $ok-space-margin-30;
    }

    &__additional-info {
        @include fontType('paragraph');
        margin-bottom: $ok-space-margin-30;
    }

    &__slider-tooltip {
        margin: 0 -15px -15px;
    }

    &__slider-tooltip-header {
        @include fontType('paragraph', true);
        padding-bottom: $ok-space-padding-10;
    }

    &__slider-tooltip-cell {
        padding: 6px $ok-space-padding-m;

        & + td {
            padding-left: 0;
            white-space: nowrap;
        }
    }

    &__slider-tooltip-footer-cell {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        background-color: $ok-background-color--first-layer;

        &--first-section {
            padding: 6px $ok-space-padding-m;
        }

        &--second-section {
            @include PrimaryFontBold($ok-font-size--medium);
            padding: 0 $ok-space-padding-m 0 0;
            white-space: nowrap;
        }
    }

    &__custom-error-text {
        padding-bottom: $ok-space-padding-30;
    }

    &__custom-error-footer {
        padding-top: $ok-space-padding-30;
    }

    &__custom-error-cell {
        padding: 6px 0;
    }
}
