$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';


.ok-informative-list-item {
    margin-bottom: 31px;

    &__title {
        @include fontType('paragraph', true);
        margin: 0;
        margin-bottom: 11px;
        color: $ok-font-color--secondary;

        &--uppercase {
            text-transform: uppercase;
        }

        &--compact {
            min-height: 25px;
            margin-bottom: 0;
        }

        &--dark {
            color:  $ok-color-oxford-base;
        }

        &--bold {
            font-weight: bold;
        }
    }

    &__title-icon {
        margin-left: 2px;
    }

    &__body-text {
        @include fontType('paragraph', true);
        min-height: 24px;
        margin: 0 0 10px;
        color: $ok-font-color--primary;
        overflow-wrap: break-word;

        &--nowrap {
            white-space: initial;
        }
    }

    &__extra-content {
        @include fontType('paragraph');
        @include CC {
            &-body-text-cif {
                @include fontType('paragraph', true);
                min-height: 24px;
                margin: 0 0 10px;
                color: $ok-color-wealth-others;
            }
        }

        margin: 8px 0 0;
    }
}
