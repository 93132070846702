$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';

.wizard-step {
    &__buttons,
    &__buttons-long {
        margin-top: 40px;
        padding-top: 21px;
        border-top: 1px solid $ok-line-color--default;
        text-align: right;
    }

    &__tooltip {
        display: inline-block;
    }

    &__first-button {
        float: left;
    }

    &__second-button,
    &__third-button,
    &__fourth-button {
        display: inline-block;
        vertical-align: bottom;
    }

    &__fourth-button {
        .buttons-base--primary {
            min-width: 80px;
        }
    }

    &__second-button {
        @include If-CC-else-webapp('margin-right', '15px', $ok-space-margin-10);
    }


    @include CC {
        &--vertical {
            &__progress-indicator {
                @include fontType('paragraph');
                display: inline-block;
                position: absolute;
                width: 35px;
                margin: 0;
                border-radius: 50px;
                background-color: $ok-highlight-color--primary;
                color: $ok-font-color--primary-inverse;
                line-height: 35px;
                text-align: center;
                box-shadow: 0 2px 6px rgba($ok-color-oxford-base, .2);
            }

            &__title {
                @include fontType('heading-type-5', true);
                display: inline;
                margin-left: 50px;
                text-align: left;
            }

            &__sub-title {
                margin-top: 10px;
                margin-left: 50px;
            }
        }
    }
}
