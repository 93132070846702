$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';

$glb-position-financial-card-header-height: 51px;

@mixin small {
    color: $ok-font-color--secondary;
    font-size: 12px;
}

.glb-position-financial-card-header {
    height: $glb-position-financial-card-header-height;

    [class^='col-'] {
        height: 100%;
        line-height: $glb-position-financial-card-header-height;
    }

    &__wealth-disclaimer {
        margin-left: 25px;
        overflow-wrap: break-word;
    }

    //(nicolas.olmos): these are not modifiers, these are BEM elements. I just take advantage of SASS feature to join classnames.
    &__title {

        &-container {
            @include text-ellipsis;
            @include If-CC-else-webapp('padding-left', '25px', '10px');
            padding-top: 8px;
        }

        &-icon {
            @include CC {
                display: none;
            }
            @include If-CC-else-webapp('margin', 'auto auto auto 13px', '0 10px 0 30px');
            position: relative;
            width: 26px;
            height: 26px;
            margin: 0 10px 0 30px;
            padding: 10px 5px 10px 20px;
            vertical-align: middle;
        }

        &-amount-items {
            @include fontType('heading-type-6');
            padding: 0 5px;
            color: $ok-font-color--secondary;
        }

        &-text {
            @include If-CC-else-webapp('margin-left', '15px', '5px');
            @include fontType('heading-type-6', true);
            @include CC {
                font-size: 18px;
            }
            color: $ok-font-color--primary;

            &--is-expanded {
                @include fontType('heading-type-5', true);
            }
        }

        &-disclaimer {
            @include fontType('caption');
            color: $ok-font-color--secondary;
        }

        &-link {
            @include fontType('heading-type-6', true);
            margin-left: 5px;
            color: $ok-font-color--primary;

            &:focus,
            &:hover {
                text-decoration: underline;
            }

            &:active,
            &:visited {
                color: $ok-font-color--primary;
            }

            &--is-expanded {
                @include fontType('heading-type-5', true);
            }
        }

        @include CC {
            &-wrap {
                padding-right: 0;
                line-height: 51px;
            }
        }
    }

    &__currency {

        &--small {
            @include small;
            padding: 0 10px;
        }

        @include CC {
            &-detail-wrap {
                padding: 0;
                line-height: 51px;
            }
        }
    }

    &__subtitle {

        &--small {
            @include small;
        }
    }

    &__cols {
        @include fontType('caption');
        padding-left: 0;
        text-align: right;
    }
}

@media only screen and (max-width: $breakpoint-sm) {
    .glb-position-financial-card-header {
        &__cols {
            padding-left: 0;
            text-align: left;
        }
    }
}
