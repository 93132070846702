$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';

.glb-position-financial-card-item {
    @include If-CC-else-webapp('margin-left', '0px', '95px');
    @include If-CC-else-webapp('padding', '5px 0px', '13px 0px');
    position: relative;
    border-bottom: solid 1px $ok-color-oxford-20;

    &:first-child {
        border-top: solid 1px $ok-color-oxford-20;
    }

    &:last-child {
        border-bottom: 0;
    }

    &__second-line {
        @include fontType('paragraph');
        display: block;
        padding: $ok-space-padding-xxxs 0 0 $ok-space-padding-xxxs;
        font-style: italic;
    }

    &__title {
        @include fontType('paragraph', true);
        margin: $ok-space-margin-xxxs $ok-space-margin-10 0 $ok-space-margin-xxxs;
        color: $ok-font-color--primary;

        &:hover {
            color: $ok-font-color--primary;
            text-decoration: underline;
        }

        &--small {
            @include fontType('paragraph');

            margin: $ok-space-margin-xxxs 0 0 $ok-space-margin-xxxs;
            color: $ok-font-color--secondary;
        }

        &--extra {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        &--wealth {
            @include fontType('paragraph');
        }
    }

    &__subtitle {
        @include If-CC-else-webapp('padding-right', '0px', '5px');
        text-align: right;

        &-label {
            padding: 0 $ok-space-padding-10;
        }

        &-amount {
            @include fontType('paragraph');
        }

        &-currency {
            padding-left: 2px;
        }
    }

    &__amount {
        @include fontType('paragraph', true);
        color: $ok-font-color--primary;

        &--wealth {
            @include fontType('paragraph');
        }
    }

    &__col {
        @include If-CC-else-webapp('margin-left', '20px', '0');
        display: inline-block;
    }

    &__content-title {
        display: inline-flex;
    }

    &__amount-object {
        text-align: right;
    }

    &__easy-loan-link {
        text-decoration: none;
    }

    &__insurance-content {
        @include fontType('paragraph', true);

        margin-left: $ok-space-margin-xxxs;
        color: $ok-color-oxford-70;
        font-weight: normal;
    }

    &__insurance-link {
        @include fontType('paragraph', true);

        margin-left: $ok-space-margin-xxxs;
        color:  $ok-color-oxford-base;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

}

@media only screen and (min-width: $breakpoint-xs) and (max-width: 700px) {
    .glb-position-financial-card-item {
        margin-left: 0;
    }
}

@media only screen and (min-width: 700px) and (max-width: $breakpoint-sm) {
    .glb-position-financial-card-item {
        margin-left: 55px;
    }
}

@media only screen and (max-width: $breakpoint-sm) {
    .glb-position-financial-card-item {
        &__col {
            display: block;
        }

        &__amount-object {
            text-align: left;
        }
    }
}
