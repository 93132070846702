// sass-lint:disable class-name-format, no-important
.dropdown-accounts {
    .Select-control {
        height:  46px;
        border: 1px solid $ok-color-oxford-30 !important;
        border-radius: 2px;
        box-shadow: none !important;
        cursor: pointer;
    }

    .Select-placeholder {
        @include fontType('paragraph');
        padding: 0 15px;
        font-style: italic;
        line-height: 46px;
    }

    .Select-input,
    .Select-value {
        height: 46px;
        line-height: 46px !important;
    }

    .Select-value {
        right: 15px !important;
        padding: 0 0 0 15px !important;
    }

    .Select-arrow {
        border-width: 5px 4px 2.5px;
        border-color: $ok-color-wealth-others transparent transparent;
    }

    .Select-menu-outer {
        border: 1px solid $ok-line-color--first-level;
        border-radius: 4px;
        box-shadow: 0 7px 20px 0 rgba($ok-color-oxford-base, .2);
    }

    .Select-option {
        padding-left: 19px;

        &.is-focused {
            background-color: rgba($ok-color-oxford-20, .3);
        }
    }

    &--error {
        .Select-control {
            border-color: $ok-error-color !important;
            background-color: rgba($ok-error-color, .05) !important;
        }
    }
}
