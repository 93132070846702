$contact-center: false;
@import '~stylesOpenBank';

.last-days-label {
    position: absolute;
    top: 26px;

    &--banner-c {
        display: inline-block;
        position: static;
        top: 0;
    }

    &__tag {
        display: inline-block;

        &:after {
            display: inline-block;
            border: 18px solid;
            border-top-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            background-color: transparent;
            content: '';
            vertical-align: middle;
        }

        &--label-small {
            &:after {
                border-width: 10px;
            }
        }

        &--label-large {
            &:after {
                border-width: 21px;
            }
        }
    }

    &__tag-content {
        @include fontType('paragraph-large', true);
        display: inline-block;
        height: 36px;
        padding: 0 $ok-space-padding-15;
        vertical-align: middle;

        &--label-small {
            @include fontType('caption', true);
            height: 20px;
            padding: 0 $ok-space-padding-10;
        }

        &--label-large {
            @include fontType('heading-type-6', true);
            height: 42px;
        }
    }

    &__tag-text {
        float: right;
        line-height: 36px;

        &--label-small {
            line-height: 20px;
        }

        &--label-large {
            line-height: 42px;
        }
    }

    &__icon {
        display: inline-block;
        margin-right: $ok-space-margin-10;

        &:before {
            display: inline-block;
            font-weight: bold;
            line-height: 36px;
        }

        &--label-small {
            &:before {
                line-height: 20px;
            }
        }

        &--label-large {
            margin-right: $ok-space-margin-15;

            &:before {
                line-height: 42px;
            }
        }
    }
}
