$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';


.ok-table-row-details-item {
    @include CC {
        display: block;
        word-break: break-word;
    }
    margin: 10px 0;

    @media only screen and (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-md) {
        @include text-ellipsis;
        max-width: 600px;
    }

    @media only screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
        @include text-ellipsis;
        max-width: 670px;
    }

    &__title {
        @include fontType('paragraph');
        @include CC {
            @include fontType('paragraph');
        }
        color: $ok-font-color--secondary;
    }

    &__title-mobile {
        @include fontType('paragraph', true);
        display: block;
        color: $ok-font-color--primary;
    }

    &__value {
        @include fontType('paragraph', true);
        @include CC {
            @include fontType('paragraph', true);
        }
        color: $ok-font-color--primary;
        word-break: break-word;
    }

    &__value-mobile {
        @include fontType('paragraph');
        display: inline-block;
        margin-bottom: 10px;
        color: $ok-font-color--primary;
    }
}
