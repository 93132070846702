$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';

.deposit-nm {
    &__custom-error-text {
        padding-bottom: $ok-space-padding-30;
    }

    &__custom-error-footer {
        padding-top: $ok-space-padding-30;
    }

    &__custom-error-cell {
        padding: 6px 0;
    }
}
