$contact-center: false;
@import '~stylesOpenBank';
@import '~stylesOpenBankCC';

.ok-header-landing {
  padding: $ok-padding-space--xxl 0 $ok-padding-space--medium;

  &__title {
    @include text-ellipsis;
    @include fontType('heading-type-2');
    margin: 0;
    color: $ok-font-color--primary-inverse;
  }

  &__buttons-section {
    @include CC {
      width: 50%;
    }

    text-align: right;

    & > * {
      margin-left: $ok-space-margin-15;
      vertical-align: middle;
    }
  }

  &__breadcrumb-section {
    padding-bottom: 15px;
  }
}

.ok-header-landing--pg {
  h1 {
    &:before {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-right: $ok-horizontal-space--small;
      margin-bottom: 3px;
      background-image: url('~assets/images/header-icon-pg.svg');
      background-repeat: no-repeat;
      content: ' ';
      vertical-align: middle;
    }
  }
}

.ok-header-landing--accounts {
  h1 {
    &:before {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-right: $ok-horizontal-space--small;
      margin-bottom: 4px;
      background-image: url('~assets/images/header-icon-accounts.svg');
      background-repeat: no-repeat;
      content: ' ';
      vertical-align: middle;
    }
  }
}

.ok-header-landing--cards {
  h1 {
    &:before {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-top: 4px;
      margin-right: $ok-horizontal-space--small;
      background-image: url('~assets/images/header-icon-cards.svg');
      background-repeat: no-repeat;
      content: ' ';
      vertical-align: middle;
    }
  }
}

.ok-header-landing--transfers {
  h1 {
    &:before {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-right: $ok-horizontal-space--small;
      margin-bottom: 1px;
      background-image: url('~assets/images/header-icon-transfers.svg');
      background-repeat: no-repeat;
      content: ' ';
      vertical-align: middle;
    }
  }
}

.ok-header-landing--deposits {
  h1 {
    &:before {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-right: $ok-horizontal-space--small;
      margin-bottom: 3px;
      background-image: url('~assets/images/header-icon-deposits.svg');
      background-repeat: no-repeat;
      content: ' ';
      vertical-align: middle;
    }
  }
}

.ok-header-landing--broker {
  h1 {
    &:before {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-right: 10px;
      background-image: url('~assets/images/header-icon-broker.svg');
      background-repeat: no-repeat;
      content: ' ';
      vertical-align: bottom;
    }
  }
}

.ok-header-landing--broker-finances-news {
  min-height: 170px;

  h1 {
    &:before {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-right: 10px;
      background-image: url('~assets/images/header-icon-broker.svg');
      background-repeat: no-repeat;
      content: ' ';
      vertical-align: bottom;
    }
  }
}

.ok-header-landing--loansmortgages {
  h1 {
    &:before {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-right: 10px;
      background-image: url('~assets/images/header-icon-loansmortgages.svg');
      background-repeat: no-repeat;
      content: ' ';
      vertical-align: middle;
    }
  }
}

.ok-header-landing--insurances {
  h1 {
    &:before {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-right: $ok-horizontal-space--small;
      margin-bottom: $ok-space-margin-xxxs;
      background-image: url('../../../assets/images/header-icon-insurances.svg');
      background-repeat: no-repeat;
      content: ' ';
      vertical-align: middle;
    }
  }
}

.ok-header-landing--aggregator-settings {
  padding: 20px 0 47px;
}

@media only screen and (max-width: $breakpoint-mobile-max) {
  .ok-header-landing {
    padding: $ok-space-padding-m 0;

    &__title {
      max-width: calc(100% - #{$ok-space-margin-m});
    }

    &__buttons-section {
      position: static;
      text-align: center;
    }
  }
}
