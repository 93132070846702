$contact-center: false;
@import '~stylesOpenBank';

.callmeback {
    @include clearfix;
    @include fontType('paragraph');
    margin-bottom: 30px;
    padding: 20px 20px 30px 30px;

    @keyframes slidy {
        0% {
            left: 0%;
        }

        20% {
            left: 0%;
        }

        25% {
            left: -100%;
        }

        45% {
            left: -100%;
        }

        50% {
            left: -200%;
        }

        70% {
            left: -200%;
        }

        75% {
            left: -300%;
        }

        95% {
            left: -300%;
        }

        100% {
            left: -400%;
        }
    }

    &__subtitle {
        @include fontType('paragraph');
    }

    &__error-section {
        @include fontType('paragraph');
        margin-bottom: 6px;

        > hr {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    &__error-title {
        @include fontType('paragraph-large', true);
        margin-top: 20px;
        margin-bottom: 5px;
    }

    &__consultant-will-be-available {
        @include fontType('paragraph');
        margin-top: 15px;
        margin-bottom: 0;
    }

    &__specialist-will-be-available {
        @include fontType('paragraph');
        margin-bottom: 0;
    }

    &__help {
        @include fontType('paragraph');
        margin-top: 8px;
    }

    &__besides-will-be-available {
        @include fontType('paragraph');
        margin-bottom: 10px;
    }

    &__img {
        display: inline-block;
        width: 72px;
        height: 72px;
        margin-bottom: 5px;
        border-radius: 38px;
        object-fit: cover;

        &--assigned-specialist {
            border: 4px solid $ok-highlight-color--secondary;
        }
    }

    &__inlineimg {
        width: 50px;
        height: 50px;
        margin-top: 25px;
        margin-left: 0;
        border-radius: 25px;
        object-fit: cover;
    }

    &__call-time-zone {
        @include fontType('caption');
        display: block;
        margin-top: 6px;
        color: $ok-font-color--secondary;
    }

    &__accordion {
        margin-right: 15px;
        margin-left: 15px;
        padding-top: 15px;
        padding-bottom: 0;

        &-wrapper {
            margin-bottom: 10px;
            background-color: $ok-background-color--first-layer;

            &--public {
                width: calc(100% + 20px);
                margin-top: 20px;
                margin-bottom: 15px;
                margin-left: -15px;
            }
        }

        &-header-title {
            @include fontType('paragraph-large', true);
            padding: 0 0 0 55px;

            &--expanded {
                height: 40px;
            }
        }

        &-body {
            height: 254px;
            margin-bottom: 10px;
            background-color: $ok-background-color--first-layer;
            overflow-y: auto;
        }

        &-img {
            margin-left: 0;
        }

        &-name {
            @include fontType('paragraph', true);
            margin-top: 20px;
            margin-left: 10px;
            color: $ok-font-color--primary;

            &--small {
                margin-top: 10px;
                margin-left: 35px;
            }
        }
    }

    &__entrance-link {
        @include fontType('paragraph-large', true);
    }

    &__entrance-link-text {
        @include fontType('paragraph');
    }

    &__entrance {
        @include fontType('paragraph-large', true);

        &-input-section {
            margin-bottom: 25px;
        }

        &-label {
            margin-bottom: 15px;
        }

        .phone-input {
            &__prefix {
                padding-right: 6px;
            }

            &__number {
                padding-left: 17px;
            }
        }
    }

    &__profile {
        margin: 20px auto;

        &-list {
            margin: 0 auto;
        }

        &-title {
            @include fontType('paragraph-large', true);
            margin-bottom: 5px;
        }

        &-info {
            margin-top: 10px;

            &--small {
                margin-top: 1px;
                margin-left: 22px;
            }
        }

        &-name {
            @include fontType('paragraph', true);
            color: $ok-font-color--primary;
        }

        &-exclusive {
            @include fontType('caption');
            color: $ok-font-color--secondary;
        }
    }

    &__pics-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100px;
    }

    &__contact-options {
        @include fontType('paragraph-large', true);
        margin: 10px 0 0;
        color: $ok-font-color--primary;

        &__phone-number {

            &:before {
                padding-right: 10px;
                color: $ok-color-wealth-mix;
                font-family: 'OpenbankFont';
                font-size: 16px;
                content: '\E965';
                vertical-align: middle;
                pointer-events: none;
            }
        }

        &__e-mail {
            @include text-ellipsis;
            margin-bottom: 0;
            padding-right: 3px;

            &:before {
                padding-right: 10px;
                color: $ok-color-wealth-mix;
                font-family: 'OpenbankFont';
                font-size: 16px;
                content: '\E967';
                vertical-align: middle;
                pointer-events: none;
            }
        }
    }

    &__close-icon {
        position: relative;
        top: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
        border: 0;
        background: 3px 3px no-repeat;
        background-position: 10px 10px;
    }

    &__icon-success {
        margin-top: 64px;
        font-size: 42px;
        font-weight: 700;
        text-align: center;

        i {
            width: 100%;
            float: none;
            background-size: inherit;
        }
    }

    &__icon-success-label {
        @include fontType('heading-type-5', true);
        width: 80%;
        margin: 0 auto;
        color: $ok-font-color--primary;
        text-align: center;
    }

    &__success-message {
        @include fontType('paragraph-large');
        margin-top: 20px;
        margin-bottom: 64px;
        color: $ok-font-color--secondary;
        text-align: center;
    }

    &__contact {
        @include fontType('paragraph-large', true);
        margin-bottom: 20px;

        &-message {
            max-width: 250px;
            margin: 20px auto 0;
        }

        &-options {
            @include fontType('paragraph', true);

            &--small {
                @include fontType('caption', true);
            }
        }

        &-phone {
            margin: 10px 0;
        }
    }

    &__icon-error {
        margin: 64px 0 10px;
        text-align: center;

        &-icon {
            color: $ok-error-color;
            font-size: 54px;
        }
    }

    &__icon-error-label {
        @include fontType('heading-type-5', true);
        width: 80%;
        margin: 0 auto;
        color: $ok-font-color--primary;
        text-align: center;
    }

    &__error-message {
        @include fontType('paragraph-large');
        margin-top: 20px;
        margin-bottom: 64px;
        color: $ok-font-color--secondary;
        text-align: center;
    }

    &__premium-diamond-specialist {
        width: 100%;
    }

    &__assigned-specialist-profile {
        margin: 15px 0;
    }

    &__tooltip-copy-button {
        margin-left: 15px;
    }

    .content-box__footer {
        height: auto;
    }
}
