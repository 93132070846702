$contact-center: false;
@import '~stylesOpenBank';

.product-banner {
    &__badge {
        display: inline;
        margin-left: $ok-space-margin-15;
    }

    &__card {
        @include layer-1;
        @include hoverBackground;
        position: relative;
        width: 100%;
        border-left-width: 4px;
        border-left-style: solid;
        border-left-color: $ok-font-color--primary;
        background-color: $ok-color-white;
        overflow: hidden;

        & + & {
            margin-top: 20px;
        }
    }

    &__left-sidebar {
        position: absolute;
        width: 150px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &__card-image {
        @include layer-8;
        display: block;
        position: relative;
        width: 90px;
        border-radius: 5px;

        &--single {
            margin: 32px auto 0;
            z-index: 1;
        }

        &--extra-front {
            right: 8px;
            margin: 45px auto 0;
            z-index: 1;
        }

        &--extra-last {
            top: -70px;
            left: 8px;
            margin: 0 auto;
            z-index: 0;
        }
    }

    &__container {
        position: relative;
        width: calc(100% - 150px);
        min-height: 276px;
        margin-left: 150px;
        padding: 32px 32px 32px 24px;
        background-color: $ok-color-white;
    }

    &__header {
        display: table;
        margin-bottom: 24px;
    }

    &__hire-button {
        margin-left: 15px;
        white-space: nowrap;
    }

    &__title-container {
        display: table-cell;
        width: 100%;
        vertical-align: top;
    }

    &__title {
        @include fontType('heading-type-6');
        color: $ok-font-color--primary;

        &--tag-free {
            margin-left: 10px;
            color: $ok-highlight-color--primary;
        }

        &--text {
            display: inline-block;

            //OBK314-78325 : Wafer title and subtitle values from cms response are wrapped in p tag.
            //In webapp it gets styling of margin-bottom 10px leading increase in wafer height.
            p {
                margin: 0;
            }
        }
    }

    &__title-tag {
        display: inline-block;
        margin-left: 15px;
    }

    &__subtitle {
        @include fontType('paragraph');
        margin-top: 5px;
        color: $ok-font-color--secondary;

        strike {
            color: $ok-highlight-color--primary;
        }

        p {
            margin: 0;
        }
    }

    &__advantages {
        display: inline-block;
        height: 100%;
    }

    &__advantage {
        display: inline-block;
        min-height: 160px;
        vertical-align: top;

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center ;
        }

        &:last-child {
            margin-right: 0;
        }

        s {
            @include fontType('paragraph');
            color: $ok-font-color--secondary-inverse;
            text-decoration-color: $ok-color-multi-primary-first;

            span {
                color: $ok-font-color--secondary;
            }
        }

        &--has-link {
            min-height: 275px;
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            justify-content: space-between;
        }

        &-column-text {
            min-height: 220px;
        }
    }

    &__advantage-icon-image {
        width: 50px;
        height: 50px;
    }

    &__advantage-text {
        @include fontType('paragraph');
        margin-top: $ok-vertical-space--small;
        color: $ok-font-color--primary;
        text-align: left;
    }

    &__advantage-title {
        @include fontType('paragraph', true);
        margin: $ok-vertical-space--small 20px 0 0;
        color: $ok-font-color--primary;
        text-align: left;
    }

    &__advantage-description {
        @include fontType('caption');
        margin: $ok-vertical-space--small 20px 0 0;
        color: $ok-font-color--primary;
        text-align: left;
    }

    &__advantage-link {
        bottom: 0;
    }

    &__retail-loan {
        min-height: 195px;
    }

    &__deposits-informative {
        overflow-wrap: break-word;
    }

    &__side-image-hidden {
        width: 100%;
        margin-left: 0;
    }

    &__primary-button-link {
        float: right;
    }

    &__secondary-button-link {
        float: left;
        text-decoration: underline !important; // sass-lint:disable-line no-important
    }

    &__complementary-link {
        display: inline-block;
        width: 100%;
        margin-top: 24px;
    }

    &__commissions-link {
        @include fontType('caption', true);
        display: inline-block;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .product-banner {

        &__subtitle {
            margin-top: 24px;
        }

        &__header {
            display: block;
            margin-bottom: 0;
        }

        &__title-container {
            display: block;
        }

        &__hire-button {
            margin: 24px 0 0;
            text-align: center;
        }

        &__container {
            width: 100%;
            margin: 0;
            padding: 20px;
        }

        &__card {
            border-top-width: 4px;
            border-left-width: 0;
            border-top-style: solid;
            border-top-color: $ok-font-color--primary;

            &-image--extra-front {
                margin: 10px 0 0 8px;
            }

            &-image--extra-last {
                margin: 0 0 0 8px;
            }

            &-image--single {
                margin: 0;
            }
        }

        &__left-sidebar {
            position: static;
            width: 100%;
            height: 168px;
            padding: 20px;
        }

        &__advantage {
            display: block;
            width: 100%;
            min-height: auto;
            margin-top: 24px;

            &-text {
                margin: 0 0 0 20px;
            }

            &-title {
                margin: 0 0 0 20px;
            }

            &-description {
                margin: 10px 0 0 20px;
            }

            &-column-text {
                min-height: auto;
                display: table-cell;
                margin-left: 20px;
                vertical-align: top;
            }

            &-column-icon {
                display: table-cell;
                vertical-align: top;
            }

            &-link {
                position: relative;
                margin: 10px 0 0 20px;
            }
        }
    }
}
